// import $ from './vendor/_jquery.min.js';

// import creative_menu from './vendor/_creative-menu.js';

// import fontAwesome from '@fortawesome/fontawesome-free/js/all.min';
import fontAwesome_solid from '@fortawesome/fontawesome-free/js/solid.min';
import fontAwesome_regular from '@fortawesome/fontawesome-free/js/regular.min';
import fontAwesome_brand from '@fortawesome/fontawesome-free/js/brands.min';
import fontAwesome from '@fortawesome/fontawesome-free/js/fontawesome.min';


$(window).on("load", function() {
	//
	// js pour l'ouverture du panel de gestion des cookies
	//
	$('a.open').on('click', function(event) {
		event.preventDefault();
		tarteaucitron.userInterface.openPanel();
	});


	//
	// js pour la réécriture des emails
	//
	function deObfuscateEmail() {
		var content = $(this).html().replace(new RegExp("{AT}", 'g'), '@').replace(new RegExp("{DOT}", 'g'), '.');
		$(this).html(content);
	}

	$('.obfuscated').each(deObfuscateEmail);


	// 
	// SCROLL POUR LES LIENS AVEC DES ANCRES
	//
    $('a[href*="#"]:not([href="#"])').on('click', function() {
        var target = $(this.hash);
        $('html,body').stop().animate({
            scrollTop: target.offset().top - 75
        }, 'linear');
    });    
    if(location.hash) {
        var id = $(location.hash);
        $('html,body').animate({scrollTop: id.offset().top -75}, 'linear');
    };


	//
	// js pour afficher/masquer le lien pour retourner en haut de page
	//
	if($('#scrollto').length) {
		var prevScrollpos = window.pageYOffset;

		window.onscroll = function() {
			var currentScrollPos = window.pageYOffset;

			if(prevScrollpos <= currentScrollPos || currentScrollPos <= 0) {
				$('#scrollto').css('right', -Math.ceil($('#scrollto').outerWidth()));
			} else {
				$('#scrollto').css('right', 0);
			}

			prevScrollpos = currentScrollPos;
		}
	}


	//
	// js pour activer/desactiver le mode passive afin d'améliorer les performances
	//
	$.event.special.touchmove = {
		setup: function(_, ns, handle) {
			if(ns.includes("noPreventDefault")) {
				this.addEventListener("touchmove", handle, { passive: false });
			} else {
				this.addEventListener("touchmove", handle, { passive: true });
			}
		}
	};

	$.event.special.touchstart = {
		setup: function(_, ns, handle) {
			if(ns.includes("noPreventDefault")) {
				this.addEventListener("touchstart", handle, { passive: false });
			} else {
				this.addEventListener("touchstart", handle, { passive: true });
			}
		}
	};

	
	//
	// js pour l'initiation de WOW.js pour les animation lors du scroll
	//
	function addFontAwesomeLi(iconClass, listId) {
		listId += ' .menu-item';

		$(listId).each(function() {
		
			let icon = document.createElement('i');
			iconClass.forEach(function(icon_class) {
				icon.classList.add(icon_class);
			});

			$(this).prepend(icon);
		});

	}
	addFontAwesomeLi(['fas','fa-chevron-right', 'mr-2'], '#quick_access');


	//
	// js pour afficher la carte
	//
	$('#maps').on('click', function(event) {
		event.preventDefault();

		let maps = $('#maps').data('google-maps');
		if(!$('#maps > iframe').length) {
            $(this).append('<iframe src="'+ maps +'" class="border-top-0 mt-3 border-bottom-0 border-left-0 border-right-0 float-left w-100" allowfullscreen></iframe>');
		} else {
			$('#maps > iframe').slideToggle();
		}

		$('html, body').stop().animate({scrollTop: $('#maps > iframe').offset().top}, 1000);
	});


	//
	// js pour l'initiation de WOW.js pour les animation lors du scroll
	//
    // wow = new WOW({
    //     boxClass:     'wow',      // default
    //     animateClass: 'animate__animated', // default
    //     offset:       0,          // default
    //     mobile:       true,       // default
    //     live:         true        // default
    // });
    // wow.init();

    AOS.init({
        once: true,
    });


	//
	// js pour l'ouverture de la popup sur le site
    //
    // if($('body').hasClass('home')) {
    //     $.fancybox.open('<div style="max-width: 70%!important"><p>Toutes les équipes Bati Colman et de ses agences de Grostenquin et Rosbruck vous souhaitent une année 2022 lumineuse !</p><img src="/wp-content/themes/gedibois/dist/images/2022-01-annee-lumineuse.jpg" class="d-none d-md-block img-fluid mt-2 mx-auto" alt="Bonne année 2022"></div>');
    // }

});